<template>
  <v-container fill-height fluid>
    <v-layout wrap>
      <v-flex md12 sm12 lg12>
        <v-col
          cols="12"
          sm="12"
          md="12"
          lg="12"
          v-if="employee && employee.name"
        >
          <v-list-item>
            <v-list-item-avatar
              color="primary"
              class="headline font-weight-light white--text"
              >{{ employee.name.charAt(0) }}</v-list-item-avatar
            >
            <v-list-item-content>
              <v-list-item-title v-text="employee.name"></v-list-item-title>
              <v-list-item-subtitle
                v-text="employee.position"
              ></v-list-item-subtitle>
              <v-list-item-subtitle
                v-text="employee.email"
              ></v-list-item-subtitle>
              <v-list-item-subtitle
                v-text="employee.employeenumber"
              ></v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-col>
        <v-tabs v-model="tab" background-color="grey lighten-2">
          <v-tab key="info">Informationen</v-tab>
          <v-tab key="docs">Dokumente ({{ documents.length }})</v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab" class="full-width">
          <v-tab-item key="info">
            <v-card class="mx-auto" flat>
              <v-card-text>
                <v-container grid-list-md>
                  <v-layout wrap>
                    <v-flex xs12 sm6 md6>
                      <v-text-field
                        v-model="employee.name"
                        label="Name*"
                        required
                      ></v-text-field>
                    </v-flex>
                    <v-flex xs12 sm6 md6>
                      <v-text-field
                        v-model="employee.employeenumber"
                        label="Mitarbeiternummer"
                      ></v-text-field>
                    </v-flex>
                    <v-flex xs12 sm4 md4>
                      <v-text-field
                        v-model="employee.position"
                        label="Position"
                      ></v-text-field>
                    </v-flex>
                    <v-flex xs12 sm4 md4>
                      <v-text-field
                        v-model="employee.location"
                        label="Standort"
                      ></v-text-field>
                    </v-flex>
                    <v-flex xs12 sm4 md4>
                      <v-select
                        :items="genders"
                        v-model="employee.gender"
                        label="Geschlecht"
                      ></v-select>
                    </v-flex>
                    <v-flex xs12 sm6 md6>
                      <v-text-field
                        type="email"
                        v-model="employee.email"
                        label="E-Mail"
                      ></v-text-field>
                    </v-flex>
                    <v-flex xs12 sm6 md6>
                      <v-text-field
                        type="phone"
                        v-model="employee.phone"
                        label="Telefon"
                      ></v-text-field>
                    </v-flex>
                    <v-flex xs12 sm8 md8>
                      <v-text-field
                        v-model="employee.street"
                        label="Straße"
                      ></v-text-field>
                    </v-flex>
                    <v-flex xs12 sm4 md4>
                      <v-text-field
                        v-model="employee.houseno"
                        label="Hausnummer"
                      ></v-text-field>
                    </v-flex>
                    <v-flex xs12 sm5 md3>
                      <v-text-field
                        type="number"
                        v-model="employee.zip"
                        max="99999"
                        label="PLZ"
                      ></v-text-field>
                    </v-flex>
                    <v-flex xs12 sm7 md9>
                      <v-text-field
                        v-model="employee.city"
                        label="Stadt"
                      ></v-text-field>
                    </v-flex>
                    <v-flex xs12 sm6 md6>
                      <v-menu
                        v-model="menu"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                      >
                        <template v-slot:activator="{ on }">
                          <v-text-field
                            v-model="employee.entrydate"
                            label="Eintrittsdatum"
                            prepend-icon="event"
                            readonly
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="employee.entrydate"
                          no-title
                          first-day-of-week="1"
                          scrollable
                          @input="menu = false"
                        ></v-date-picker>
                      </v-menu>
                    </v-flex>
                    <v-flex xs12 sm6 md6>
                      <v-menu
                        v-model="menu2"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                      >
                        <template v-slot:activator="{ on }">
                          <v-text-field
                            v-model="employee.exitdate"
                            label="Vertragsende"
                            prepend-icon="event"
                            readonly
                            v-on="on"
                            persistent-hint
                            hint="Leer lassen bei unbefristetem Vertrag"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="employee.exitdate"
                          first-day-of-week="1"
                          no-title
                          scrollable
                          @input="menu2 = false"
                        ></v-date-picker>
                      </v-menu>
                    </v-flex>
                    <v-flex xs12 sm6 md6>
                      <v-select
                        :items="supervisors"
                        item-value="id"
                        item-text="name"
                        v-model="employee.supervisorid"
                        label="Vorgesetzter"
                      ></v-select>
                    </v-flex>
                    <v-flex xs12 sm6 md6>
                      <v-select
                        :items="supervisors"
                        item-value="id"
                        item-text="name"
                        v-model="employee.cosupervisorid"
                        label="Stellver. Vorgesetzter"
                      ></v-select>
                    </v-flex>
                    <v-flex xs12 sm6 md6>
                      <v-select
                        :items="roles"
                        v-model="employee.role"
                        label="Rolle"
                      ></v-select>
                    </v-flex>
                    <v-flex xs12 sm6 md6>
                      <v-text-field
                        v-model="employee.password"
                        label="Password"
                      ></v-text-field>
                    </v-flex>
                    <v-flex xs12 sm6 md6>
                      <v-text-field
                        type="number"
                        v-model="employee.workinghours"
                        label="Arbeitsstunden pro Wocche"
                      ></v-text-field>
                    </v-flex>
                    <v-flex xs12 sm6 md6>
                      <v-select
                        :items="workingweek"
                        v-model="employee.workingweek"
                        label="Arbeitswoche"
                        item-text="text"
                        item-value="value"
                      ></v-select>
                    </v-flex>
                    <v-flex xs12 sm6 md6>
                      <v-switch
                        v-model="employee.active"
                        label="Aktiv"
                      ></v-switch>
                    </v-flex>
                  </v-layout>
                </v-container>
                <small>* Diese Felder müssen Sie ausfüllen</small>
              </v-card-text>
            </v-card>
            <!--  <v-col cols="12" sm="12" md="12" lg="5">
            <div class="mt-0 card-title">Kontingent Übersicht</div>
            <v-simple-table>
              <template v-slot:default>
                <tbody>
                  <tr>
                    <td>
                      <b>Soll</b>
                    </td>
                    <td>90 Stunden</td>
                  </tr>
                  <tr>
                    <td>
                      <b>Geleistet</b>
                    </td>
                    <td>20 Stunden</td>
                  </tr>
                  <tr>
                    <td>
                      <b>Urlaub</b>
                    </td>
                    <td>19 Tage</td>
                  </tr>
                  <tr>
                    <td>
                      <b>Krank</b>
                    </td>
                    <td>20 Stunden</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
            </v-col>-->
            <v-btn color="success" @click="updateData()"
              >Informationen aktualisieren</v-btn
            >
          </v-tab-item>
          <v-tab-item key="docs">
            <v-card class="mx-auto" flat>
              <v-card-text>
                <v-data-table
                  :headers="headersdocuments"
                  height="inherit"
                  hide-default-footer
                  :items-per-page="-1"
                  :items="documents"
                >
                  <template v-slot:top>
                    <v-toolbar flat>
                      <v-toolbar-title
                        >Dokumente ({{ documents.length }})</v-toolbar-title
                      >
                    </v-toolbar>
                  </template>
                  <template v-slot:item.created_at="{ item }">{{
                    $formatDateTime(item.created_at)
                  }}</template>
                  <template v-slot:item.filename="{ item }">
                    <div class="link" @click="$openDoc(item)">
                      {{ item.filename }}
                    </div>
                  </template>
                  <template v-slot:item.action="{ item }">
                    <div class="text-center inline-flex">
                      <v-tooltip left>
                        <template v-slot:activator="{ on }">
                          <v-btn
                            v-on="on"
                            @click="confirmDeleteDoc(item)"
                            color="error"
                            small
                            fab
                          >
                            <i class="material-icons">delete</i>
                          </v-btn>
                        </template>
                        <span>Dokument löschen</span>
                      </v-tooltip>
                    </div>
                  </template>
                  <template v-slot:footer>
                    <hr class="my-5" />
                    <v-row>
                      <v-col cols="12" sm="9" md="9" lg="9">
                        <v-file-input
                          v-model="docs"
                          color="primary accent-4"
                          counter
                          label="Hier klicken um Dokumente hochzuladen"
                          multiple
                          placeholder="Hier klicken um Dokumente hochzuladen"
                          prepend-icon="mdi-paperclip"
                          outlined
                          :show-size="1000"
                        ></v-file-input>
                      </v-col>
                      <v-col cols="12" sm="3" md="3" lg="3">
                        <v-btn
                          @click="uploadDocs()"
                          color="primary"
                          :disabled="docs.length === 0 || !online"
                          >Dokumente Hochladen</v-btn
                        >
                      </v-col>
                    </v-row>
                  </template>
                </v-data-table>
              </v-card-text>
            </v-card>
          </v-tab-item>
        </v-tabs-items>
      </v-flex>
    </v-layout>
    <v-layout row justify-center>
      <v-dialog v-model="confirm" persistent max-width="400">
        <v-card>
          <v-card-title class="headline">Dokument Löschen</v-card-title>
          <v-card-text>
            Sind sie sicher, dass Sie das Dokument
            <b>{{ deleteItem.filename }}</b> unwiederruflich löschen wollen?
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="success" text @click="deleteDoc()">Ja</v-btn>
            <v-btn color="error" text @click="confirm = false">Nein</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-layout>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      menu: false,
      menu2: false,
      confirm: false,
      tab: "",
      deleteItem: {},
      documents: [],
      docs: [],
      supervisors: [],
      headersdocuments: [
        {
          text: "ID",
          align: "left",
          sortable: false,
          value: "id",
        },
        {
          text: "Bezeichnung",
          align: "left",
          sortable: false,
          value: "filename",
        },
        {
          text: "Hochgeladen von",
          align: "left",
          value: "creator",
        },
        {
          text: "Hochgeladen am",
          align: "left",
          value: "created_at",
        },
        {
          text: "Aktion",
          align: "center",
          sortable: false,
          value: "action",
        },
      ],
      employee: {
        name: "",
      },
      workingweek: [
        {
          text: "5-Tage-Woche",
          value: "5",
        },
        {
          text: "6-Tage-Woche",
          value: "6",
        },
      ],
      genders: [
        {
          text: "Männlich",
          value: "m",
        },
        {
          text: "Weiblich",
          value: "w",
        },
      ],
      roles: [
        {
          text: "Service-Techniker",
          value: "worker",
        },
        {
          text: "Projektleiter",
          value: "pl",
        },
        {
          text: "Admin",
          value: "admin",
        },
      ],
    };
  },
  mounted: function () {
    this.getEmployee();
  },
  computed: {
    id() {
      return this.$route.params.id;
    },
    online() {
      return this.$store.getters.online;
    },
  },
  methods: {
    getEmployee() {
      /*  this.$http
        .get("user/user/" + this.id) */
      let formData = new FormData();
      formData.append("id", this.id);
      this.$http({
        method: "post",
        url: "getEmployeeInfo.php",
        data: formData,
      })
        .then((response) => {
          if (
            response.status === 500 &&
            response.message === "NoLoggedInUser"
          ) {
            let axios = this.$http;
            this.$store
              .dispatch("logout", { axios })
              .then((resp) => {
                this.$router.push("/login");
              })
              .catch((err) => {
                console.log(err);
              });
          }
          if (
            response.data &&
            response.data.success === false &&
            response.data.notAllowed
          ) {
            this.$router.push("/");
          }
          if (response.status === 200 && response.data) {
            const id = this.$store.getters.user.id;
            const companyid = this.$store.getters.user.companyid;
            const role = this.$store.getters.user.role;
            /* if (
              (id !== response.data.info.id && role != "admin") ||
              companyid != response.data.info.companyid
            ) {
              this.$router.push("/");
            } */
            if (response.data.info) {
              const employee = response.data.info;
              employee.active = employee.active == "1";
              this.employee = employee;
            }
            if (response.data.supervisors) {
              this.supervisors = response.data.supervisors;
            }
          }
          if (response.data.documents) {
            this.documents = response.data.documents;
          }
        })
        .catch((error) => {
          this.$router.push("/");
        });
    },
    deleteDoc() {
      const item = this.deleteItem;
      this.$deleteDoc(item.id, item.path)
        .then((response) => {
          if (
            response.data &&
            response.data.success === false &&
            response.data.missingToken
          ) {
            let axios = this.$http;
            this.$store
              .dispatch("logout", { axios })
              .then((resp) => {
                this.$router.push("/login");
              })
              .catch((err) => {
                console.log(err);
              });
          }
          if (
            response.data &&
            response.data.success === false &&
            response.data.authorization
          ) {
            alert(
              "Sie haben keine Berechtigung für diese Funktion. Bitte wenden Sie sich an einen Administrator"
            );
            return;
          }
          if (response.data && response.data.success) {
            this.documents.forEach((a, i) => {
              if (a.id === this.deleteItem.id) {
                this.documents.splice(i, 1);
                this.deleteItem = {};
                this.confirm = false;
              }
            });
            const msg = "Das Dokument wurde erfolgreich gelöscht.";
            const color = "success";
            this.$store.dispatch("snackbar", { msg, color });
          } else {
            const msg =
              "Das Dokument konnten nicht gelöscht werden. Bitte versuchen Sie es erneut.";
            const color = "error";
            this.$store.dispatch("snackbar", { msg, color });
          }
        })
        .catch(function (error) {
          const msg =
            "Das Dokument konnte nicht gelöscht werden. Bitte versuchen Sie es erneut.";
          const color = "error";
          this.$store.dispatch("snackbar", { msg, color });
        });
    },
    uploadDocs() {
      this.$uploadDocs(this.docs, this.id, "employee", "employee")
        .then((response) => {
          if (
            response.data &&
            response.data.success === false &&
            response.data.missingToken
          ) {
            let axios = this.$http;
            this.$store
              .dispatch("logout", { axios })
              .then((resp) => {
                this.$router.push("/login");
              })
              .catch((err) => {
                console.log(err);
              });
          }
          if (response.data && response.data.success && response.data.files) {
            const customerid = this.id;
            const username = this.$store.getters.user.name;
            const userid = this.$store.getters.user.id;
            response.data.files.forEach((file) => {
              this.documents.push({
                id: file.id,
                path: file.path,
                filename: file.filename,
                created_at: this.$formatDateTimeISO(new Date()),
                created_by: userid,
                creator: username,
                customerid: customerid,
              });
            });
            this.docs = [];
            const msg = "Dokumente wurden erfolgreich hochgeladen.";
            const color = "success";
            this.$store.dispatch("snackbar", { msg, color });
          } else {
            const msg =
              "Dokumente konnten nicht hochgeladen werden. Bitte versuchen Sie es erneut.";
            const color = "error";
            this.$store.dispatch("snackbar", { msg, color });
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    confirmDeleteDoc(item) {
      this.deleteItem = item;
      this.confirm = true;
    },
    updateData() {
      /*  this.$http
        .put("user/updateuser/", employee) */
      let formData = new FormData();
      let employee = JSON.parse(JSON.stringify(this.employee));
      employee.active = employee.active || employee.active === 1 ? 1 : 0;
      formData.append("mode", "edit");
      formData.append("json", JSON.stringify(employee));
      this.$http({
        method: "post",
        url: "editEmployee.php",
        data: formData,
      })
        .then((response) => {
          if (
            response.status === 500 &&
            response.message === "NoLoggedInUser"
          ) {
            let axios = this.$http;
            this.$store
              .dispatch("logout", { axios })
              .then((resp) => {
                this.$router.push("/login");
              })
              .catch((err) => {
                console.log(err);
              });
          }
          if (
            response.data &&
            response.data.success === false &&
            response.data.authorization
          ) {
            alert(
              "Sie haben keine Berechtigung für diese Funktion. Bitte wenden Sie sich an einen Administrator"
            );
            return;
          }
          if (response.data && response.data.success) {
            const msg = "Die Änderungen wurden erfolgreich gespeichert.";
            const color = "success";
            this.$store.dispatch("snackbar", { msg, color });
          } else {
            const msg =
              "Die Änderungen konnten nicht gespeichert werden. Bitte versuchen Sie es erneut.";
            const color = "error";
            this.$store.dispatch("snackbar", { msg, color });
          }
        })
        .catch(error => {
          const msg =
            "Die Änderungen konnten nicht gespeichert werden. Bitte versuchen Sie es erneut.";
          const color = "error";
          this.$store.dispatch("snackbar", { msg, color });
        });
    },
  },
};
</script>
